import * as yup from "yup";

export const BASE_ROUTE_URL = "/dashboard";
export const BASE_TITLE = " | My Family";

export const CONFIG = {
  layout: "vertical",
  collapseMenu: false,
  layoutType: "menu-dark",
};

export const mobileNumberSchema = yup
  .string()
  .matches(
    /^\+\d{1,2}(?:\s)?\d{7,14}$/,
    "Enter your phone number with country code followed by your number (e.g., +91 1234567890)"
  ).nullable(true);

export const birthDateSchema = yup
  .string()
  .matches(/^\d{4}\-\d{2}\-\d{2}$/, "Please enter valid format (DD/MM/YYYY) e.x. 12/02/1978")
  .required("Please enter valid Birth date");

export const convertToISOFormat = (dateString) => {
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
};

export const convertToDisplayFormat = (isoDateString) => {
  const [year, month, day] = isoDateString.split("-");
  return `${day}/${month}/${year}`;
};

export const passowrdSchema = yup
  .string()
  .required("Password is required")
  .min(8, 'Password must be at least 8 characters long')
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/,
    "New Password must contain one uppercase letter, one lowercase letter, one number, and one special character"
  );