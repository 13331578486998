import localStorageKeys from "../utils/localStorageKeys";

// Local Storage
function setLocalItem(key, value) {
  localStorage.setItem(key, value);
}

function getLocalItem(key) {
  return localStorage.getItem(key);
}

export default class Storage {
  // Login user data
  static setLoginUserData(userInfo) {
    setLocalItem(localStorageKeys.LOGIN_USER_DATA, JSON.stringify(userInfo));
  }

  static getLoginUserData() {
    let data = getLocalItem(localStorageKeys.LOGIN_USER_DATA);
    return data ? JSON.parse(data) : null;
  }

  static getLoginUserDataFromToken() {
    const loggedInUserData = Storage.getLoginUserData();
    const userDetails = loggedInUserData?.user?.token
      ? JSON.parse(atob(loggedInUserData?.user?.token?.split(".")[1]))
      : { userId: "" };
    return userDetails;
  }

  // Clear Local Storage
  static clearLocalStorage() {
    return localStorage.clear();
  }
}
