import React, { createContext, useState, useContext, useCallback } from "react";
import { CloseButton, Toast, ToastContainer } from "react-bootstrap";
import PropTypes from "prop-types";
import "./ToastProvider.css";

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const showToast = useCallback((variant, message, title) => {
    const id = Math.random().toString(36).substr(2, 9);
    setToasts((prevToasts) => [...prevToasts, { id, variant, message, title }]);

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 3000);
  }, []);

  const handleClose = (toastId) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== toastId)
    );
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer position="bottom-center" className="p-3 toast-container">
        {toasts.map((toast) => (
          <Toast key={toast.id} autohide bg={toast.variant}>
            <Toast.Body>
              <strong className="me-auto text-white">{toast.message}</strong>
              <CloseButton
                style={{ float: "right" }} // Optional styling
                variant="white"
                onClick={() => handleClose(toast.id)}
              />
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastProvider;
