import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

// auth provider

import routes, { renderRoutes } from './routes';
import Storage from './services/storage';
import { useDispatch } from 'react-redux';
import { authenticate } from './store/authReducer';
import Loader from './components/Loader/Loader';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userData = Storage.getLoginUserData();
    if (userData?.user?.token) dispatch(authenticate());
    setIsLoading(false);
  }, [dispatch]);

  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>{renderRoutes(routes)}</BrowserRouter>
    </React.Fragment>
  );
};

export default App;
