// action - state management
import Storage from '../services/storage';
import { LOGIN, LOGOUT, AUTHENTICATE } from './actions';

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

export const login = (details) => async (dispatch) => {
  dispatch({ type: LOGIN, payload: details });
};

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const authenticate = () => async (dispatch) => {
  dispatch({ type: AUTHENTICATE });
};

// ==============================|| AUTH REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE: {
      return {
        ...state,
        isLoggedIn: true
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      Storage.setLoginUserData(action.payload);
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      Storage.setLoginUserData('');
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default authReducer;
