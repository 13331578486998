import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';

const reducers = combineReducers({
  form: formReducer,
  auth: authReducer
});

export default reducers;
