import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import { BASE_ROUTE_URL } from "./config/constant";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = useSelector((store) => store.auth.isLoggedIn);
  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/home" replace state={{ from: location }} />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;
        const isPrivate = route.isPrivate; // Assuming `isPrivate` is a boolean property in the route object

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                {isPrivate ? (
                  <PrivateRoute>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Element props={true} />
                      )}
                    </Layout>
                  </PrivateRoute>
                ) : (
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Element props={true} />
                    )}
                  </Layout>
                )}
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: "true",
    path: "/home",
    element: lazy(() => import("./views/home")),
    isPrivate: false,
  },
  {
    exact: "true",
    path: "/signin",
    element: lazy(() => import("./views/auth/signin/SignIn")),
    isPrivate: false,
  },
  {
    exact: "true",
    path: "/signup",
    element: lazy(() => import("./views/auth/signup/SignUp")),
    isPrivate: false,
  },
  {
    exact: "true",
    path: "/forgot-password",
    element: lazy(() => import("./views/auth/reset-password/ForgotPassword")),
    isPrivate: false,
  },
  {
    exact: "true",
    path: "/change-password",
    element: lazy(() => import("./views/auth/change-password/ResetPassword")),
    isPrivate: false,
  },
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: "true",
        path: "/dashboard",
        element: lazy(() => import("./views/dashboard")),
        isPrivate: true,
      },
      {
        exact: "true",
        path: "/settings",
        element: lazy(() => import("./views/settings")),
        isPrivate: true,
      },
      {
        exact: "true",
        path: "/family",
        element: lazy(() => import("./views/family")),
        isPrivate: true,
      },
      {
        exact: "true",
        path: "/profile",
        element: lazy(() => import("./views/profile")),
        isPrivate: true,
      },
      {
        exact: "true",
        path: "/logout",
        element: lazy(() => import("./views/auth/logout/Logout")),
        isPrivate: true,
      },
      {
        path: "*",
        exact: "true",
        element: () => <Navigate to={BASE_ROUTE_URL} />,
        isPrivate: true,
      },
    ],
  },
];

export default routes;
